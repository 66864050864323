<template>
    <v-container class="pa-4 py-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
            <v-icon>mdi-new-box</v-icon>
            Create New IRA Coverage
        </div>

        <v-stepper
            v-model="e6"
            vertical
            >
            <v-stepper-step
                :complete="e6 > 1"
                step="1"
                >
                Select a Date Range
            </v-stepper-step>

            <v-stepper-content step="1">
                <v-row class="pl-4">
                    <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="startDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="startDate"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="startDate"
                            :allowed-dates="allowedDates"
                            no-title
                            scrollable
                            :min="getMin"
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu1 = false"
                                >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu1.save(startDate)"
                                >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-row>

                <v-row class="pl-4">
                     <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="endDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="endDate"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endDate"
                            :allowed-dates="allowedDatesEnd"
                            no-title
                            scrollable
                            :min="getMin"
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu2 = false"
                                >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu2.save(endDate)"
                                >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-row>

                <v-row class="pt-4 pl-4">
                    <v-btn
                        color="primary"
                        @click="e6 = 2"
                        v-if="startDate !== getMin && endDate !== getMin"
                        >
                        Continue
                    </v-btn>
                </v-row>
            </v-stepper-content>

            <v-stepper-step
                :complete="e6 > 2"
                step="2"
                >
                Select IRA Rows
            </v-stepper-step>
            <v-stepper-content
             step="2"
             >  
                <div>
                    <v-btn
                        color="primary"
                        @click="saveCoverage"
                        >
                        Save
                    </v-btn>
                    <v-btn
                        class="ml-1"
                        @click="e6 -= 1"
                        >
                        Back
                    </v-btn>
                </div>
            </v-stepper-content>

        </v-stepper>
    </v-container>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            e6: 1,
            coverageID: '',
            startDate: null,
            endDate: null,
            menu1: false,
            menu2: false,
            selected: [],
            headers: [
                { text: 'Risk Name', value: 'RiskName.RiskName' },
                {
                    text: 'Risk Classification',
                    align: 'start',
                    sortable: true,
                    value: 'RiskClassification.RiskClassification',
                },
                { text: 'Affected Stakeholders', value: 'AffectedStakeholders' },
                { text: 'Risk Treatment Owner', value: 'RiskTreatmentOwner' },
                { text: 'Likelihood', value: 'LikelihoodLevel.Level' },
                { text: 'Impact', value: 'ImpactLevel.Level' },
                { text: 'Impact Classification', value: 'ImpactClassification.name' },
                { text: 'Vulnerability', value: 'Vulnerability' },
                { text: 'Risk Strategy', value: 'RiskStrategy.RiskStrategy' },
                { text: 'Information', value: 'information' },
            ],
        }
    },
    created() {
    this.setDefaultDate()
    this.initialize()
  },

    firestore(){
      return {
        ORMPs: this.$db.collection('ORMP'),
        ORMPLevels: this.$db.collection('ORMPLevels'),
        IRACoverage: this.$db.collection('IRACoverage'),
        Departments: this.$db.collection('Departments'),
        AffectedStakeholder: this.$db.collection('AffectedStakeholder'),
        RisksIndicatorsIR: this.$db.collection('RiskIndicators-IR'),
        DepartmentIR: this.$db.collection('Department-IR'),
        IRAReportIR: this.$db.collection('IRAReport-IR'),
        ControlIndicatorsCC: this.$db.collection('ControlIndicators-CC')
      }
    },

    methods: {
        initialize () {
            this.desserts = this.ORMPs.filter(a=>{
            return this.deptFilter == a.Department
            })
        },
        async saveDateRange() {
             let data = {
                startDate: this.startDate,
                endDate: this.endDate,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department,
            }
            let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new ORMP Coverage?`,
                title: `Confirm New ORMP Coverage`
            })
            if(confirm){
                self.$db.collection('IRACoverage').add(data).then(async (doc)=>{
                        let id = doc.id
                        await self.$store.dispatch('logs/createNotifs', {collection:'IRACoverage',documentKey:id,module: 'IRA',action: 'Added New IRA Coverage'}) 
                        self.$dialog.notify.success(`Added New ORMP Coverage`, {
                        position: 'bottom-right',
                        timeout: 3000
                      })      
                      self.$router.push('/viewallormp/'+id)      
                    })    
            }
        },
        async saveCoverage() {
            let data = {
                startDate: this.startDate,
                endDate: this.endDate,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department,
            }
            let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new IRA Coverage?`,
                title: `Confirm New IRA Coverage`
            })

            if(confirm){
                    self.$db.collection('IRACoverage').add(data)
                    .then(async (doc)=>{
                        let id = doc.id
                        this.coverageID = doc.id
                        await self.$store.dispatch('logs/createNotifs', {collection:'IRACoverage',documentKey:id,module: 'IRA',action: 'Added New IRA Coverage'}) 
                         self.$dialog.notify.success(`Added New IRA Coverage`, {
                             position: 'bottom-right',
                            timeout: 3000
                   })
                    }).then(() => {
                         let arrIndicators = []
                         let CopyRiskIndicatorIR = []
                         let arrDepartmentId = []
                         let arrDepartment = []
                         this.DepartmentIR.forEach(e=>{
                            arrDepartmentId.push(e['.key'])
                            arrDepartment.push(e.Department)
                         })
                        this.RisksIndicatorsIR.forEach(e=>{   
                            let x = {...e}
                            let indicatorKey = x['.key']
                            delete x['.key']
                            x.RiskIndicatorId = indicatorKey
                            x.IRACoverageId = this.coverageID
                            x.DepartmentToMap = this.DepartmentIR    
                            arrIndicators.push(x)
                        })
                        arrIndicators.map((risk) => {
                            risk.DepartmentToMap.map((d) => {
                            CopyRiskIndicatorIR.push({ ...risk, DepartmentId: d['.key'], Department: d.Department });
                            })
                        });
                        
                        CopyRiskIndicatorIR.forEach(e=>{
                            let x = {...e}
                            delete x.DepartmentToMap
                            x.Dividend = 0
                            x.Divisor = 0
                            x.Likelihood = 0
                            x.IsLikelihoodNull = false
                            x.ImpactScore = 0
                            x.CreatedAt = this.$firebase.firestore.FieldValue.serverTimestamp()
                            x.CreatedBy = this.$store.getters['useraccount/isAuthenticated'].email
                            x.Modified = this.$firebase.firestore.FieldValue.serverTimestamp()
                            self.$db.collection('IRAReport-IR').add(x)
                        })
                       
                        
                    }).then(()=>{
                        this.ControlIndicatorsCC.forEach(e=>{
                            let x = {...e}
                            let indicatorKey = x['.key']
                            delete x['key']
                            x.ControlIndicatorId = indicatorKey
                            x.IRACoverageId = this.coverageID
                            x.StatusOfControl = null
                            x.Score = null
                            x.CreatedAt = this.$firebase.firestore.FieldValue.serverTimestamp()
                            x.CreatedBy = this.$store.getters['useraccount/isAuthenticated'].email
                            x.Modified = this.$firebase.firestore.FieldValue.serverTimestamp()
                            self.$db.collection('IRAReport-CC').add(x)
                        })
                         self.$router.push('/viewallira/' + this.coverageID)
                        this.coverageID = ''
                    })
                //   self.$db.collection('ORMPCoverage').add(data)
                //  .then((doc)=>{
                //     let id = doc.id
                //     this.coverageID = doc.id
                //     let selected = this.selected
                //     selected.forEach(e => {
                //         let x = {...e}
                //         delete x['.key']
                //         x.coverageId = id
                //         x.CreatedAt = this.$firebase.firestore.FieldValue.serverTimestamp()
                //         x.CreatedBy = this.$store.getters['useraccount/isAuthenticated'].email
                //         x.Department = this.$store.getters['useraccount/isAuthenticated'].department

                //      this.$db.collection('ORMP').add(x)
                //     .then((doc) => {
                //        let y = {...x}
                //         let ORMPId = doc.id 
                     
                //            y.ORMPId = ORMPId
                //            y.RiskManagementLevel = 'Inherent'
                //         this.$db.collection('ORMPLevels').add(y).then(()=> {
                //             self.$dialog.notify.success(`Added New ORMP`, {
                //             position: 'bottom-right',
                //             timeout: 3000
                //                 })  
                //             })
                //         })                   
                //     })  
                //     self.$dialog.notify.success(`Added New ORMP Coverage`, {
                //             position: 'bottom-right',
                //             timeout: 3000
                //     })
                //     self.$router.push('/viewallormp/' + this.coverageID)  
                //  })
            }
        },
        setDefaultDate(){
            this.startDate = this.getMin
            this.endDate = this.getMin
        },

        levelStatusTime(item) {
        let hello = []
        let filter = this.ORMPLevels.filter(e => {
         return e.ORMPId === item['.key'] 
        })
          filter.forEach(e=> {
            hello.push({RiskManagementLevel: e.RiskManagementLevel, CreatedAt: e.CreatedAt})
          })
          let order = this.$lodash.orderBy(hello, ['CreatedAt'], ['desc'])
          let mapped = order.map(e=> {
            return e.RiskManagementLevel + " " + 'created on' + " " + moment(e.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')
          })
          return mapped[0]
      },
       isSameOrAfterAndBefore(date,start,end){
           console.log(this.$moment(date).isSameOrAfter(start) && this.$moment(date).isSameOrBefore(end))
          return this.$moment(date).isSameOrAfter(start) && this.$moment(date).isSameOrBefore(end) 
      },
      isDateinIRACoverage(value){
          console.log(value,value);
          let cov = this.IRACoverage.filter(a=>{
              let IRAstartDate = a.startDate
              let IRAendDate = a.endDate
              return this.$moment(value).isBetween(IRAstartDate,IRAendDate) || this.isSameOrAfterAndBefore(value,IRAstartDate,IRAendDate)
          })
          return cov.length > 0
      },
      allowedDates(val) {
          return !this.isDateinIRACoverage(val)
      },
      allowedDatesEnd(val) {
          return !this.isDateinIRACoverage(val) && val > this.startDate
      }
      
    },
    computed: {
        count(){
            return this.IRAReportIR.length
        },
        getMin() {
            // var d = new Date(new Date().getFullYear() + 1, 0, 2);
            // return d.toISOString().substring(0, 7);

            var d = new Date(new Date().getFullYear() + 1, 0, 2);
            return d.toISOString().substring(0, 10)
        },

        getMax() {
            //  var d = new Date(new Date().getFullYear() + 1, 11, 2);
            // return d.toISOString().substring(0, 7);

            var d = new Date(new Date().getFullYear() + 1, 11, 2);
            return d.toISOString().substring(0, 10)
        },

        returnORMPs(){
        let before = this.ORMPs
        let self = this

        let user = self.$store.getters['useraccount/isAuthenticated']
        
       
            return self.$lodash.uniqBy(before.filter(a=>{
                return a.Department == user.department
            }), e=>{
                return e.RiskName.RiskName
            })          
        
      },

    }
}
</script>